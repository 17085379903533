import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useFirebaseAuth, useProfile } from "./Firebase";
import Login from "./Login";
import AnalyticsPage from "./pages/bloomcatch/AnalyticsPage";
import { AnalyticsPage as PartnerAnalyticsPage } from "./pages/partners/AnalyticsPage";
import { PlantCarePlansPage as PartnerPlantCarePlansPage } from "./pages/partners/PlantCarePlansPage";

import { ErrorBoundary } from "@sentry/react";
import ClonePlantCarePlanPage from "./pages/bloomcatch/ClonePlantCarePlanPage";
import EditPlantCarePlanPage from "./pages/bloomcatch/EditPlantCarePlanPage";
import NewPlantCarePlanPage from "./pages/bloomcatch/NewPlantCarePlanPage";
import PartnerPage from "./pages/bloomcatch/PartnerPage";
import PartnersPage from "./pages/bloomcatch/PartnersPage";
import PlantCarePlansPage from "./pages/bloomcatch/PlantCarePlansPage";
import { EditPlantCarePlanPage as PartnerEditPlantCarePlanPage } from "./pages/partners/EditPlantCarePlanPage";
import { BloomcatchTemplate } from "./templates/BloomcatchTemplate";

export default function App() {
  const { loggedIn } = useFirebaseAuth();
  const profile = useProfile();
  const router = createBrowserRouter([
    {
      path: "/",
      element: profile?.partnerId ? (
        <BloomcatchTemplate>
          <PartnerAnalyticsPage />
        </BloomcatchTemplate>
      ) : (
        <BloomcatchTemplate>
          <PlantCarePlansPage />
        </BloomcatchTemplate>
      ),
    },
    {
      path: "/analytics",
      element: profile?.partnerId ? (
        <BloomcatchTemplate>
          <PartnerAnalyticsPage />
        </BloomcatchTemplate>
      ) : (
        <BloomcatchTemplate>
          <AnalyticsPage />
        </BloomcatchTemplate>
      ),
    },
    {
      path: "/partners",
      element: (
        <BloomcatchTemplate>
          <PartnersPage />
        </BloomcatchTemplate>
      ),
    },
    {
      path: "/partners/:partnerId",
      element: (
        <BloomcatchTemplate>
          <PartnerPage />
        </BloomcatchTemplate>
      ),
    },
    {
      path: "/plant-care-plans",
      element: profile?.partnerId ? (
        <BloomcatchTemplate>
          <PartnerPlantCarePlansPage />
        </BloomcatchTemplate>
      ) : (
        <BloomcatchTemplate>
          <PlantCarePlansPage />
        </BloomcatchTemplate>
      ),
    },
    {
      path: "/plant-care-plans/clone/:plantCarePlanId",
      element: (
        <BloomcatchTemplate>
          <ClonePlantCarePlanPage />
        </BloomcatchTemplate>
      ),
    },
    {
      path: "/plant-care-plans/edit/:plantCarePlanId",
      element: profile?.partnerId ? (
        <BloomcatchTemplate>
          <PartnerEditPlantCarePlanPage />
        </BloomcatchTemplate>
      ) : (
        <BloomcatchTemplate>
          <EditPlantCarePlanPage />
        </BloomcatchTemplate>
      ),
    },
    {
      path: "/plant-care-plans/new",
      element: (
        <BloomcatchTemplate>
          <NewPlantCarePlanPage />
        </BloomcatchTemplate>
      ),
    },
  ]);

  return (
    <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
      {loggedIn === false && <Login></Login>}
      {loggedIn === true && profile && <RouterProvider router={router} />}
    </ErrorBoundary>
  );
}
