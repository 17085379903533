import { ErrorBoundary } from "@sentry/react";
import { useRequest } from "alova";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/button";
import { alovaInstance } from "../../../lib/api";
import { Config } from "../../Config";
import { useFirebaseAuth } from "../../Firebase";

interface RawData {
  partner: { id: string; name: string };
  partnerPlantCarePlans: {
    id: string;
    partnerId: string;
    plantCarePlanId: string;
  }[];
  plantCarePlans: { id: string; title: string; isCustom: boolean }[];
}

export default function PartnerPage() {
  const { partnerId } = useParams();
  const navigate = useNavigate();
  const { accessToken } = useFirebaseAuth();

  const {
    loading,
    data: rawData,
    error,
    send: getAssociations,
  } = useRequest(
    alovaInstance.Get<{ data: RawData }>(
      `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/partner/${encodeURI(
        partnerId!
      )}/associations`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    ),
    { force: true }
  );

  const { send: updateAssociations } = useRequest(
    (updatedAssociations: { add: string[]; remove: string[] }) =>
      alovaInstance.Put(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/partner/${encodeURI(
          partnerId!
        )}/associations`,
        updatedAssociations,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      ),
    {
      immediate: false,
    }
  );

  const { send: createCustomPlantCarePlan } = useRequest(
    (createCustomPlantCarePlan: { plantCarePlanId: string }) =>
      alovaInstance.Post(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan/${encodeURI(createCustomPlantCarePlan.plantCarePlanId)}/custom/for-partner/${encodeURI(partnerId!)}`,
        undefined,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      ),
    {
      immediate: false,
    }
  );

  const { send: removeCustomPlantCarePlan } = useRequest(
    (removeCustomPlantCarePlan: { plantCarePlanId: string }) =>
      alovaInstance.Delete(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan/${encodeURI(removeCustomPlantCarePlan.plantCarePlanId)}/custom/for-partner/${encodeURI(partnerId!)}`,
        undefined,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      ),
    {
      immediate: false,
    }
  );

  const [plantCarePlans, setPlantCarePlans] =
    useState<{ id: string; title: string; isCustom: boolean }[]>();
  const [selectedPlantCarePlanIds, setSelectedPlantCarePlanIds] = useState(
    new Set<string>()
  );

  useEffect(() => {
    if (!rawData) {
      setPlantCarePlans(undefined);
      return;
    }

    const selectedPlantCarePlanIds = new Set<string>();
    rawData.data.partnerPlantCarePlans.forEach((item) =>
      selectedPlantCarePlanIds.add(item.plantCarePlanId)
    );
    setSelectedPlantCarePlanIds(selectedPlantCarePlanIds);

    setPlantCarePlans(
      rawData.data.plantCarePlans.map((item) => ({
        id: item.id,
        title: item.title,
        isCustom: item.isCustom,
      }))
    );
  }, [rawData]);

  const handlePlantCarePlanClicked = (plantCarePlanId: string) => {
    if (selectedPlantCarePlanIds.has(plantCarePlanId)) {
      selectedPlantCarePlanIds.delete(plantCarePlanId);
      setSelectedPlantCarePlanIds(new Set(selectedPlantCarePlanIds));
      return;
    }

    selectedPlantCarePlanIds.add(plantCarePlanId);
    setSelectedPlantCarePlanIds(new Set(selectedPlantCarePlanIds));
  };

  const handleDeselectAll = () => {
    setSelectedPlantCarePlanIds(new Set<string>());
  };

  const handleCancel = () => {
    navigate("/partners");
  };

  const handleSave = () => {
    const originallySelectedPlantCarePlanIds = new Set<string>();
    rawData.data.partnerPlantCarePlans.forEach((item) =>
      originallySelectedPlantCarePlanIds.add(item.plantCarePlanId)
    );

    const plantCarePlanIdsToBeAdded = Array.from(
      selectedPlantCarePlanIds
    ).filter((id) => !originallySelectedPlantCarePlanIds.has(id));
    const plantCarePlanIdsToBeDeleted = Array.from(
      originallySelectedPlantCarePlanIds
    ).filter((id) => !selectedPlantCarePlanIds.has(id));

    console.log(plantCarePlanIdsToBeAdded, plantCarePlanIdsToBeDeleted);

    updateAssociations({
      add: plantCarePlanIdsToBeAdded,
      remove: plantCarePlanIdsToBeDeleted,
    }).then(() => {
      navigate("/partners");
    });
  };

  const handleCreateCustomPlantCarePlan = (plantCarePlanId: string) => {
    createCustomPlantCarePlan({ plantCarePlanId }).then(() => {
      getAssociations();
    });
  };

  const handleRemoveCustomPlantCarePlan = (plantCarePlanId: string) => {
    removeCustomPlantCarePlan({ plantCarePlanId }).then(() => {
      getAssociations();
    });
  };

  return (
    <>
      <header>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            {loading && <>Loading...</>}
            {error && <>Partner</>}
            {rawData?.data && <>{rawData.data.partner.name}</>}
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
            {loading && <div>Loading...</div>}
            {error && <div>{error.message}</div>}
            {plantCarePlans && (
              <ul role="list" className="divide-y divide-gray-100">
                {plantCarePlans.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between gap-x-6 py-5"
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <div className="min-w-0 flex items-center flex-auto">
                        <label className="text-sm font-semibold leading-6 text-gray-900 ml-2">
                          <input
                            type="checkbox"
                            className="accent-green-700 mr-2"
                            checked={selectedPlantCarePlanIds.has(item.id)}
                            onChange={() => handlePlantCarePlanClicked(item.id)}
                          />
                          {item.title}
                          {item.isCustom ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                              className="size-4 inline-block"
                            >
                              <path d="M5.5 3.5A1.5 1.5 0 0 1 7 2h2.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 1 .439 1.061V9.5A1.5 1.5 0 0 1 12 11V8.621a3 3 0 0 0-.879-2.121L9 4.379A3 3 0 0 0 6.879 3.5H5.5Z" />
                              <path d="M4 5a1.5 1.5 0 0 0-1.5 1.5v6A1.5 1.5 0 0 0 4 14h5a1.5 1.5 0 0 0 1.5-1.5V8.621a1.5 1.5 0 0 0-.44-1.06L7.94 5.439A1.5 1.5 0 0 0 6.878 5H4Z" />
                            </svg>
                          ) : null}
                        </label>
                      </div>
                    </div>
                    <div className="hidden shrink-0 sm:flex">
                      <p className="text-gray-900 flex items-center ">
                        {item.isCustom ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6 hover:text-green-700 hover:cursor-pointer mr-2"
                            onClick={() =>
                              handleRemoveCustomPlantCarePlan(item.id)
                            }
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6 hover:text-green-700 hover:cursor-pointer mr-2"
                            onClick={() =>
                              handleCreateCustomPlantCarePlan(item.id)
                            }
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {plantCarePlans && (
              <div>
                <Button className="mr-2" onClick={() => handleDeselectAll()}>
                  Deselect All
                </Button>
                <Button className="mr-2" onClick={() => handleSave()}>
                  Save
                </Button>
                <Button className="mr-2" onClick={() => handleCancel()}>
                  Cancel
                </Button>
              </div>
            )}
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
}
