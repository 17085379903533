import { DialogTitle } from "@headlessui/react";
import { useRef, useState } from "react";
import { Cropper, CropperRef } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/button";
import { Dialog, DialogActions, DialogBody } from "../../../components/dialog";
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "../../../components/fieldset";
import { Input } from "../../../components/input";
import { Text } from "../../../components/text";
import { Textarea } from "../../../components/textarea";

type Inputs = {
  title: string;
  comment: string;
  sections: { type: string; title: string; description: string }[];
  plantImageUrl: string;
};

interface PlantCarePlanFormProps {
  data?: Inputs;
  onSave: (data: Inputs) => void;
}

export function PlantCarePlanForm({ data, onSave }: PlantCarePlanFormProps) {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: data });
  const { fields /* append, prepend, remove, swap, move, insert */ } =
    useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "sections", // unique name for your Field Array
    });

  const onSubmit: SubmitHandler<Inputs> = (data) =>
    onSave({ ...data, plantImageUrl: croppedImage ?? data.plantImageUrl });

  const handleClickCancel = () => {
    navigate("/plant-care-plans");
  };

  const cropperRef = useRef<CropperRef>(null);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileInput = () => {
    fileInput!.current!.click();
  };

  const handleFileSelected = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;
    if (files && files[0]) {
      const blob = URL.createObjectURL(files[0]);
      setFile64(blob);
      setIsOpen(true);
    }
  };

  const [file64, setFile64] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const handlePhotoUpdates = () => {
    setCroppedImage(
      cropperRef.current?.getCanvas({ height: 512, width: 512 })?.toDataURL() ??
        null
    );
    setIsOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset>
          <FieldGroup className="py-4">
            <Field>
              <Label>Title</Label>
              <Input {...register("title", { required: true })} />
            </Field>
            <Field>
              <Label>Photo</Label>
              <Text>
                <img
                  src={croppedImage ?? getValues().plantImageUrl}
                  alt="Plant"
                  className="w-32 h-32 object-cover rounded-md border border-gray-300 bg-white"
                />
              </Text>
            </Field>
            <Field>
              <Text>
                <Button type="button" onClick={() => handleFileInput()}>
                  Update Photo
                </Button>
                <input
                  type="file"
                  hidden
                  ref={fileInput}
                  accept="image/png, image/jpeg"
                  onChange={(event) => handleFileSelected(event)}
                />
                <Dialog open={isOpen} onClose={setIsOpen}>
                  <DialogTitle>Update Photo</DialogTitle>
                  <DialogBody>
                    <Cropper
                      src={file64!}
                      className={"cropper"}
                      ref={cropperRef}
                      stencilProps={{ aspectRatio: 1 }}
                    />
                  </DialogBody>
                  <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                      Cancel
                    </Button>
                    <Button onClick={() => handlePhotoUpdates()}>Update</Button>
                  </DialogActions>
                </Dialog>
              </Text>
            </Field>
          </FieldGroup>
          <div
            className="overflow-hidden rounded-md border border-gray-300 bg-white mt-6"
            hidden={!getValues().sections || getValues().sections.length === 0}
          >
            <ul role="list" className="divide-y divide-gray-300">
              {fields.map((field, index) => {
                return (
                  <li key={field.title} className="px-6 py-4">
                    <FieldGroup>
                      <Field>
                        <Label>{field.title}</Label>
                        <Textarea
                          {...register(
                            `sections.${index}.description` as const
                          )}
                        />
                      </Field>
                      {/* <Field>
                        <Button
                          className="text-xs"
                          outline
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Field> */}
                    </FieldGroup>
                  </li>
                );
              })}
            </ul>
          </div>
        </Fieldset>
        {errors.title && <span>This field is required</span>}
        <div className="mt-6 flex items-center justify-end gap-x-2">
          <Button type="submit">Save</Button>
          <Button onClick={() => handleClickCancel()}>Cancel</Button>
        </div>
      </form>
    </>
  );
}
