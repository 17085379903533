import { ErrorBoundary } from "@sentry/react";
import { useRequest } from "alova";
import { useNavigate } from "react-router-dom";
import { alovaInstance } from "../../../lib/api";
import { Config } from "../../Config";
import { useFirebaseAuth, useProfile } from "../../Firebase";

const PlantCarePlansPage = () => {
  const navigate = useNavigate();
  const { accessToken } = useFirebaseAuth();
  const profile = useProfile();

  const {
    loading,
    data: plantCarePlans,
    error,
    send: getPartnerPlantCarePlans,
  } = useRequest(
    alovaInstance.Get<{
      data: {
        id: string;
        title: string;
        partnerName: string;
        isCustom: boolean;
      }[];
    }>(`${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }),
    { force: true }
  );

  const { send: createCustomPlantCarePlan } = useRequest(
    (createCustomPlantCarePlan: {
      plantCarePlanId: string;
      partnerId: string;
    }) =>
      alovaInstance.Post(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan/${encodeURI(createCustomPlantCarePlan.plantCarePlanId)}/custom/for-partner/${encodeURI(createCustomPlantCarePlan.partnerId)}`,
        undefined,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      ),
    {
      immediate: false,
    }
  );

  const { send: removeCustomPlantCarePlan } = useRequest(
    (removeCustomPlantCarePlan: {
      plantCarePlanId: string;
      partnerId: string;
    }) =>
      alovaInstance.Delete(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan/${encodeURI(removeCustomPlantCarePlan.plantCarePlanId)}/custom/for-partner/${encodeURI(removeCustomPlantCarePlan.partnerId)}`,
        undefined,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      ),
    {
      immediate: false,
    }
  );

  const handleCreateCustomPlantCarePlan = (
    plantCarePlanId: string,
    partnerId: string
  ) => {
    createCustomPlantCarePlan({ plantCarePlanId, partnerId }).then(() => {
      getPartnerPlantCarePlans();
    });
  };

  const handleRemoveCustomPlantCarePlan = (
    plantCarePlanId: string,
    partnerId: string
  ) => {
    removeCustomPlantCarePlan({ plantCarePlanId, partnerId }).then(() => {
      getPartnerPlantCarePlans();
    });
  };

  const handleEditPlantCarePlan = (plantCarePlanId: string) => {
    navigate(`/plant-care-plans/edit/${encodeURI(plantCarePlanId)}`);
  };

  return (
    <>
      <header>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Plant Care Plans
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
            {loading && <div>Loading...</div>}
            {error && <div>{error.message}</div>}
            {plantCarePlans?.data && (
              <ul className="divide-y divide-gray-100">
                {plantCarePlans.data.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between gap-x-6 py-5"
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <div className="min-w-0  items-center flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {item.title}
                          {item.isCustom ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                              className="size-4 inline-block"
                            >
                              <path d="M5.5 3.5A1.5 1.5 0 0 1 7 2h2.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 1 .439 1.061V9.5A1.5 1.5 0 0 1 12 11V8.621a3 3 0 0 0-.879-2.121L9 4.379A3 3 0 0 0 6.879 3.5H5.5Z" />
                              <path d="M4 5a1.5 1.5 0 0 0-1.5 1.5v6A1.5 1.5 0 0 0 4 14h5a1.5 1.5 0 0 0 1.5-1.5V8.621a1.5 1.5 0 0 0-.44-1.06L7.94 5.439A1.5 1.5 0 0 0 6.878 5H4Z" />
                            </svg>
                          ) : null}
                        </p>
                        <p className="text-xs font-normal leading-6 text-gray-500">
                          {item.partnerName}
                        </p>
                      </div>
                    </div>
                    <div className="hidden shrink-0 sm:flex">
                      <p className="text-gray-900 flex items-center ">
                        {item.isCustom && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 hover:text-green-700"
                            onClick={() => handleEditPlantCarePlan(item.id)}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                            />
                          </svg>
                        )}
                        {!!profile &&
                          !!profile.partnerId &&
                          (item.isCustom ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 hover:text-green-700 hover:cursor-pointer mr-2"
                              onClick={() =>
                                handleRemoveCustomPlantCarePlan(
                                  item.id,
                                  profile.partnerId!
                                )
                              }
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 hover:text-green-700 hover:cursor-pointer mr-2"
                              onClick={() =>
                                handleCreateCustomPlantCarePlan(
                                  item.id,
                                  profile.partnerId!
                                )
                              }
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                              />
                            </svg>
                          ))}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
};

export { PlantCarePlansPage };
