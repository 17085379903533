import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { User, getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { alovaInstance } from "../lib/api";

const firebaseConfig = {
  apiKey: "AIzaSyCjbA9WtufIdBUNq0siBt7x1_RMK2IvtDI",
  authDomain: "level-dragon-413511.firebaseapp.com",
  projectId: "level-dragon-413511",
  storageBucket: "level-dragon-413511.appspot.com",
  messagingSenderId: "534446118302",
  appId: "1:534446118302:web:d56ac5eea86b8af8bd328f",
  measurementId: "G-8LR9V8M46W",
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
analytics.app.automaticDataCollectionEnabled = true;

const perf = getPerformance(app);
perf.dataCollectionEnabled = true;
perf.instrumentationEnabled = true;

const auth = getAuth(app);
auth.tenantId = "partner-content-vc81e";

const authUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(
  null
);
authUser.next(auth.currentUser);
auth.onAuthStateChanged((user) => {
  (async () => {
    if (user) {
      const accessToken = await user.getIdToken();
      authAccessToken.next(accessToken);
    } else {
      authAccessToken.next(null);
    }
    authUser.next(user);
  })();
});

const authAccessToken: BehaviorSubject<string | null> = new BehaviorSubject<
  string | null
>(null);
auth.onIdTokenChanged((user) => {
  (async () => {
    if (user) {
      const accessToken = await user.getIdToken();
      authAccessToken.next(accessToken);
    } else {
      authAccessToken.next(null);
    }
  })();
});

const useFirebaseAuth = () => {
  const [accessToken, setAccessToken] = useState<string | null>(
    authAccessToken.value
  );
  const [loggedIn, setLoggedIn] = useState<boolean>();
  const [user, setUser] = useState<User | null>(authUser.value);

  useEffect(() => {
    const accessTokenSubscription = authAccessToken.subscribe((accessToken) => {
      setAccessToken(accessToken);
    });

    const userSubscription = authUser.subscribe((user) => {
      setLoggedIn(!!user);
      setUser(user);
    });

    return () => {
      accessTokenSubscription.unsubscribe();
      userSubscription.unsubscribe();
    };
  }, []);

  const signOut = useCallback(() => {
    auth.signOut();
  }, []);

  return {
    accessToken,
    loggedIn,
    signOut,
    user,
  };
};

const profileAtom = atom<
  | {
      administrator: boolean;
      partnerAdministrator: boolean;
      partnerId?: string;
    }
  | undefined
>(undefined);

const useProfile = () => {
  const [profile, setProfile] = useAtom(profileAtom);

  const { accessToken, user } = useFirebaseAuth();

  useEffect(() => {
    if (!user) {
      setProfile(undefined);
    } else {
      alovaInstance
        .Get(
          `${import.meta.env.VITE__PARTNER_API__BASE_URL}/bff/partner-admin/v1/partner-content-user`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response: any) => {
          setProfile({
            administrator: !response.data.partnerId,
            partnerAdministrator: !!response.data.partnerId,
            partnerId: response.data.partnerId,
          });
        });
    }
  }, [accessToken, setProfile, user]);

  return profile;
};

export { analytics, app, auth, perf, useFirebaseAuth, useProfile };
