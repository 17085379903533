import { ErrorBoundary } from "@sentry/react";
import { useRequest } from "alova";
import { useNavigate } from "react-router-dom";
import { alovaInstance } from "../../../lib/api";
import { Config } from "../../Config";
import { useFirebaseAuth } from "../../Firebase";
import { PlantCarePlanForm } from "./PlantCarePlanForm";

export default function NewPlantCarePlanPage() {
  const navigate = useNavigate();
  const { accessToken } = useFirebaseAuth();

  const { send: createPlantCarePlan } = useRequest(
    (createPlantCarePlan: {
      title: string;
      comment: string;
      sections: string[];
      plantImageUrl: string;
    }) =>
      alovaInstance.Post(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan`,
        createPlantCarePlan,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      ),
    {
      immediate: false,
    }
  );

  const handleSave = (data: {
    title: string;
    comment: string;
    sections: string[];
    plantImageUrl: string;
  }) => {
    createPlantCarePlan(data);
    navigate("/plant-care-plans");
  };

  return (
    <>
      <header>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            New Plant Care Plan
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
            <PlantCarePlanForm
              data={{
                title: "",
                comment: "",
                sections: [],
                plantImageUrl:
                  "https://cdn.bloomcatch-servers.com/partner-content/plant-image/none.png",
              }}
              onSave={(data) =>
                handleSave({
                  title: data.title,
                  comment: data.comment,
                  sections: [],
                  plantImageUrl: data.plantImageUrl,
                })
              }
            />
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
}
