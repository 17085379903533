import { ErrorBoundary } from "@sentry/react";
import { useRequest } from "alova";
import { useNavigate } from "react-router-dom";
import { alovaInstance } from "../../../lib/api";
import { Config } from "../../Config";
import { useFirebaseAuth } from "../../Firebase";

export default function PartnersPage() {
  const navigate = useNavigate();
  const { accessToken } = useFirebaseAuth();

  const { loading, data, error } = useRequest(
    alovaInstance.Get<{
      data: { id: string; name: string; logoUrl: string }[];
    }>(`${Config.partnerApi.baseUrl}/bff/partner-admin/v1/partner`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }),
    { force: true }
  );

  const handlePartnerClick = (partnerId: string) => {
    navigate(`/partners/${encodeURI(partnerId)}`);
  };

  const handleDownloadPartnerQrCodesClick = (partnerId: string) => {
    alovaInstance
      .Get<{ data: string }>(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/partner-plant-care-plan/qr-codes/by-partner/${encodeURI(partnerId)}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          localCache: null,
        }
      )
      .then((response) => {
        const w = window.open("");
        w!.document.write(response.data);
      });
  };

  return (
    <>
      <header>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Partners
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
            {loading && <div>Loading...</div>}
            {error && <div>{error.message}</div>}
            {data?.data && (
              <ul role="list" className="divide-y divide-gray-100">
                {data.data.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between gap-x-6 py-5"
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <img
                        className="h-12 w-12 object-scale-down rounded"
                        src={item.logoUrl}
                        alt=""
                      />
                      <div className="min-w-0 flex items-center flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {item.name}
                        </p>
                      </div>
                    </div>
                    <div className="hidden shrink-0 sm:flex">
                      <p className="text-gray-900 flex items-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 hover:text-green-700 hover:cursor-pointer mr-2"
                          onClick={() => handlePartnerClick(item.id)}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 hover:text-green-700 hover:cursor-pointer"
                          onClick={() =>
                            handleDownloadPartnerQrCodesClick(item.id)
                          }
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"
                          />
                        </svg>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
}
