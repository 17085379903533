import { ResponsiveBarCanvas } from "@nivo/bar";
import { ErrorBoundary } from "@sentry/react";
import { useRequest } from "alova";
import { json2csv } from "json-2-csv";
import { alovaInstance } from "../../../lib/api";
import { Config } from "../../Config";
import { useFirebaseAuth } from "../../Firebase";

// User visits/day for each plant partner

interface RawData {
  data: {
    totalClicks: number;
    clicks: number;
    date: Date;
  };
}

interface AllData {
  data: [
    {
      totalClicks: number;
      clicks: number;
      date: Date;
    },
  ];
}

export default function AnalyticsPage() {
  const { accessToken } = useFirebaseAuth();

  const {
    loading,
    data: rawData,
    error,
  } = useRequest(
    alovaInstance.Get<RawData>(
      `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/analytics/users-per-day`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    ),
    {
      localCache: {
        mode: "memory",
        expire: 60 * 1000,
      },
    }
  );

  const handleClick = () => {
    alovaInstance
      .Get<AllData>(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/analytics/all-data`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          localCache: null,
        }
      )
      .then(async (data) => {
        const csv = await json2csv(data.data);
        const blob = new Blob([csv], { type: "text/csv" });
        window.open(window.URL.createObjectURL(blob), "_blank");
      });
  };

  return (
    <>
      <header>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Analytics
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleClick}
            >
              Download Analytics
            </button>
            {loading && <div>Loading...</div>}
            {error && <div>{error.message}</div>}
            {rawData && (
              <div className="aspect-video h-96">
                <MyResponsiveBarCanvas data={rawData.data} />
              </div>
            )}
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
}

const MyResponsiveBarCanvas = ({ data /* see data tab */ }: { data: any }) => (
  <>
    <ResponsiveBarCanvas
      data={data}
      indexBy="date"
      keys={["clicks"]}
      valueScale={{ type: "linear" }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Date",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
        format: (value) => {
          const date = new Date(value);
          return `${date.getMonth() + 1}/${date.getDate()}`;
        },
      }}
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      colors={{ scheme: "nivo" }}
    />
    <ResponsiveBarCanvas
      data={data}
      indexBy="date"
      keys={["totalClicks"]}
      valueScale={{ type: "symlog" }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Date",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
        format: (value) => {
          const date = new Date(value);
          return `${date.getMonth() + 1}/${date.getDate()}`;
        },
      }}
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      colors={{ scheme: "nivo" }}
    />
  </>
);
