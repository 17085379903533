import { ErrorBoundary } from "@sentry/react";
import { useRequest } from "alova";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/button";
import { alovaInstance } from "../../../lib/api";
import { Config } from "../../Config";
import { useFirebaseAuth } from "../../Firebase";

export default function PlantCarePlansPage() {
  const navigate = useNavigate();
  const { accessToken } = useFirebaseAuth();

  const {
    loading,
    data: plantCarePlans,
    error,
  } = useRequest(
    alovaInstance.Get<{
      data: { id: string; title: string; partnerName: string }[];
    }>(`${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }),
    { force: true }
  );

  const handleEditPlantCarePlan = (plantCarePlanId: string) => {
    navigate(`/plant-care-plans/edit/${encodeURI(plantCarePlanId)}`);
  };

  const handleNewPlantCarePlan = () => {
    navigate("/plant-care-plans/new");
  };

  return (
    <>
      <header>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Plant Care Plans
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
            {loading && <div>Loading...</div>}
            {error && <div>{error.message}</div>}
            {plantCarePlans?.data && (
              <ul role="list" className="divide-y divide-gray-100">
                {plantCarePlans.data.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between gap-x-6 py-5"
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <div className="min-w-0  items-center flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {item.title}
                        </p>
                        <p className="text-xs font-normal leading-6 text-gray-500">
                          {item.partnerName}
                        </p>
                      </div>
                    </div>
                    <div className="hidden shrink-0 sm:flex">
                      <p className="text-gray-900 flex items-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 hover:text-green-700"
                          onClick={() => handleEditPlantCarePlan(item.id)}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {plantCarePlans?.data && (
              <div>
                <Button
                  className="mr-2"
                  onClick={() => handleNewPlantCarePlan()}
                >
                  New Plant Care Plan
                </Button>
              </div>
            )}
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
}
