import { ResponsiveBarCanvas } from "@nivo/bar";
import { useRequest } from "alova";
import { alovaInstance } from "../../../lib/api";
import { Config } from "../../Config";
import { useFirebaseAuth } from "../../Firebase";
import { ErrorBoundary } from "@sentry/react";

// User visits/day for each plant partner

interface RawData {
  data: {
    totalClicks: number;
    clicks: number;
    date: Date;
  };
}

const AnalyticsPage = () => {
  const { accessToken } = useFirebaseAuth();

  const {
    loading,
    data: rawData,
    error,
  } = useRequest(
    alovaInstance.Get<RawData>(
      `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/analytics/users-per-day`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    ),
    {
      localCache: {
        mode: "memory",
        expire: 60 * 1000,
      },
    }
  );

  return (
    <>
      <header>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Analytics
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
            {loading && <div>Loading...</div>}
            {error && <div>{error.message}</div>}
            {rawData && (
              <div className="aspect-video h-96">
                <MyResponsiveBarCanvas data={rawData.data} />
              </div>
            )}
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
};

const MyResponsiveBarCanvas = ({ data /* see data tab */ }: { data: any }) => (
  <>
    <h2 className="text-2xl font-bold tracking-tight text-gray-700">
      Daily QR Code Scans
    </h2>
    <p className="text-gray-700">
      This chart shows the number of QR code scans per day.
    </p>
    <ResponsiveBarCanvas
      data={data}
      indexBy="date"
      keys={["clicks"]}
      valueScale={{ type: "linear" }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Date",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
        format: (value) => {
          const date = new Date(value);
          return `${date.getMonth() + 1}/${date.getDate()}`;
        },
      }}
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      colors={{ scheme: "nivo" }}
    />
  </>
);

export { AnalyticsPage };
