import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BuildingOffice2Icon,
  ChartBarSquareIcon,
  ClipboardDocumentListIcon,
  LockOpenIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FC, Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFirebaseAuth, useProfile } from "../Firebase";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const BloomcatchTemplate: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { signOut } = useFirebaseAuth();
  const profile = useProfile();
  const navigate = useNavigate();

  const bloomcatchNavigation = [
    {
      name: "Plant Care Plans",
      href: "/plant-care-plans",
      icon: ClipboardDocumentListIcon,
      current: false,
    },
    {
      name: "Partners",
      href: "/partners",
      icon: BuildingOffice2Icon,
      current: false,
    },
    {
      name: "Analytics",
      href: "/analytics",
      icon: ChartBarSquareIcon,
      current: false,
    },
    {
      name: "Logout",
      icon: LockOpenIcon,
      current: false,
      href: "#",
      handleClick: () => {
        navigate("/", { replace: true });
        signOut();
      },
    },
  ];
  const partnerNavigation = [
    {
      name: "Plant Care Plans",
      href: "/plant-care-plans",
      icon: ClipboardDocumentListIcon,
      current: false,
    },
    {
      name: "Analytics",
      href: "/analytics",
      icon: ChartBarSquareIcon,
      current: false,
    },
    {
      name: "Logout",
      icon: LockOpenIcon,
      current: false,
      href: "#",
      handleClick: () => {
        navigate("/", { replace: true });
        signOut();
      },
    },
  ];
  const navigation = profile
    ? profile.administrator
      ? bloomcatchNavigation
      : partnerNavigation
    : [];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src="https://static.wixstatic.com/media/dec614_07f9a1e958fb436c984fd7a1b66c23ca~mv2.png/v1/fill/w_361,h_107,al_c,lg_1,q_85,enc_auto/BloomCatch_Logo_draft-2a.png"
                      alt="BloomCatch Logo"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-50 text-indigo-600"
                                    : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                                onClick={(e) => {
                                  if (item.handleClick) {
                                    e.preventDefault();
                                    item.handleClick();
                                  }
                                }}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-indigo-600"
                                      : "text-gray-400 group-hover:text-indigo-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src="https://static.wixstatic.com/media/dec614_07f9a1e958fb436c984fd7a1b66c23ca~mv2.png/v1/fill/w_361,h_107,al_c,lg_1,q_85,enc_auto/BloomCatch_Logo_draft-2a.png"
              alt="BloomCatch Logo"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-50 text-indigo-600"
                            : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                        onClick={(e) => {
                          if (item.handleClick) {
                            e.preventDefault();
                            item.handleClick();
                          }
                        }}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-indigo-600"
                              : "text-gray-400 group-hover:text-indigo-600",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
};
