import { ErrorBoundary } from "@sentry/react";
import { useRequest } from "alova";
import { useNavigate, useParams } from "react-router-dom";
import { alovaInstance } from "../../../lib/api";
import { Config } from "../../Config";
import { useFirebaseAuth } from "../../Firebase";
import { PlantCarePlanForm } from "./PlantCarePlanForm";

interface RawData {
  data: {
    title: string;
    comment: string;
    sections: { type: string; title: string; description: string }[];
    plantImageUrl: string;
  };
}

export default function EditPlantCarePlanPage() {
  const { plantCarePlanId } = useParams();
  const navigate = useNavigate();
  const { accessToken } = useFirebaseAuth();

  const {
    loading,
    data: rawData,
    error,
  } = useRequest(
    alovaInstance.Get<RawData>(
      `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan/${encodeURI(plantCarePlanId!)}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    ),
    { force: true }
  );

  const { send: updatePlantCarePlan } = useRequest(
    (updatePlantCarePlan: {
      title: string;
      comment: string;
      sections: { type: string; title: string; description: string }[];
      plantImageUrl: string;
    }) =>
      alovaInstance.Put(
        `${Config.partnerApi.baseUrl}/bff/partner-admin/v1/plant-care-plan/${encodeURI(plantCarePlanId!)}`,
        updatePlantCarePlan,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      ),
    {
      immediate: false,
    }
  );

  const handleSave = (data: {
    title: string;
    comment: string;
    sections: { type: string; title: string; description: string }[];
    plantImageUrl: string;
  }) => {
    updatePlantCarePlan(data).then(() => {
      navigate("/plant-care-plans");
    });
  };

  const handleClone = (plantCarePlanId: string) => {
    navigate(`/plant-care-plans/clone/${encodeURI(plantCarePlanId!)}`);
  };

  return (
    <>
      <header>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            {loading && <>Loading...</>}
            {error && <>Plant Care Plan</>}
            {rawData && <>{rawData.data.title}</>}
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <ErrorBoundary fallback={<p>⚠️ Something went wrong</p>}>
            {loading && <div>Loading...</div>}
            {error && <div>{error.message}</div>}
            {rawData && (
              <PlantCarePlanForm
                data={rawData.data}
                onClone={() => handleClone(plantCarePlanId!)}
                onSave={(data) =>
                  handleSave({
                    title: data.title,
                    comment: data.comment,
                    sections: data.sections,
                    plantImageUrl: data.plantImageUrl,
                  })
                }
              />
            )}
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
}
